define("discourse/plugins/discourse-journal/discourse/components/journal-topic-tip", ["exports", "@glimmer/component", "float-kit/components/d-tooltip", "discourse-common/helpers/i18n", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dTooltip, _i18n, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class JournalTopicTip extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="journal-topic-tip">
          <DTooltip @placement="right-start">
            <:trigger>
              <div class="btn btn-topic-tip">
                <span class="d-button-label">{{i18n @label}}</span>
                {{dIcon "info-circle"}}
              </div>
            </:trigger>
            <:content>
              <div class="tip-details">
                {{i18n @details}}
              </div>
            </:content>
          </DTooltip>
        </div>
      
    */
    {
      "id": "Hf1CO+7L",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"journal-topic-tip\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@placement\"],[\"right-start\"]],[[\"trigger\",\"content\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"btn btn-topic-tip\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"d-button-label\"],[12],[1,[28,[32,1],[[30,1]],null]],[13],[1,\"\\n            \"],[1,[28,[32,2],[\"info-circle\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[10,0],[14,0,\"tip-details\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[[30,2]],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@details\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-journal/discourse/components/journal-topic-tip.js",
      "scope": () => [_dTooltip.default, _i18n.default, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = JournalTopicTip;
  ;
});